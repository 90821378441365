<template>
  <div class="blog-post">
    <Navbar/>  
    <div class="container-fluid">
      <SubNav>
        <template #link1>
          <router-link to="/blog"> 
            <u>Blog</u>>
          </router-link>
        </template>
        <template #current>
          Бесплатная магистратура во Франции
        </template>
      </SubNav>
      <div class="row">
        <div class="col-12 offset-lg-3 col-lg-6">
          <PostHeader>
            <template #title>
                Бесплатная магистратура во Франции. <br>
                В каком возрасте поступать во французский вуз. <br>
                Бесплатная учеба во Франции на английском языке, возможно ли? 
            </template>
            <template #date>
                15.05.2021
            </template>
          </PostHeader>

          <PostBodyIntroduction>
              <template #introduction>
                <p>
                    Если за плечами у вас уже есть высшее образование в своей стране, то тратить снова пять лет жизни на получение новой специальности, как правило, не хочется. К сожалению, российские дипломы не очень ценятся на европейском рынке труда (хотя все зависит от востребованности вашей профессии). Что же делать в таком случае? Поступить в магистратуру во Франции. Вы можете пройти процедуру подтверждения вашего диплома. Как правило, 5 лет образования в России приравниваются к 4-м годам во Франции. 
                </p>
              </template>
          </PostBodyIntroduction>

          <PostBodySection>
            <template #title>
              Магистратура во Франции бесплатно.
            </template>
            <template #content>
                <p>
                    После подтверждения диплома (или до) самое время выбрать подходящую программу обучения. Еще недавно иностранные студенты могли поступить в магистратуру во Франции практически бесплатно, а именно наравне с французскими студентами. Не так давно французские власти приняли закон о повышении платы для иностранцев в 10 раз. Теперь бесплатная магистратура во Франции - скорее миф, чем правда. Тем не менее, некоторые вузы пока что оставили старые тарифы. 
                </p>
                <p>
                    Средняя цена учебы во французском вузе для иностранца - 3000 евро. Это не такие большие деньги, если учесть уровень жизни во Франции. На жилье и еду вам в любом случае придется тратить около 1000 евро в месяц. Совмещать учебу во французской магистратуре и работу (например, официантом или продавцом в магазине) - вполне реально. Французская система образования построена таким образом, что большую часть времени студент отводит самостоятельному изучению. Вы можете сами планировать свой день. Кто-то предпочитает учиться дома, но французские студенты обожают проводить время в библиотеках - при университетах или городских. 
                </p>
                <p>
                    Бесплатная магистратура во Франции пока еще возможна, но для этого стоит тщательно выбирать вуз и смотреть тарифы по каждому конкретному заведению. Тем не менее, учеба в университете в любом случае выйдет дешевле, чем образование в высшей школе. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Можно ли учиться бесплатно во Франции на английском языке.
            </template>
            <template #content>
                <p>
                    Для тех, кто не знает французский язык, но мечтает учиться во Франции, есть два варианта развития событий. Первый - поступить на языковые курсы при университете, которые всегда бывают платными. Второй - искать высшее образование во Франции на английском языке. Почти наверняка оно будет платным. Программы по магистратуре на английском языке предлагают многие бизнес-школы Франции, это тоже официальный диплом высшего образования. Найти магистратуру на английском языке бесплатно в университете Франции - вариант, практически равный нулю. Мы не будем говорить, что он не возможен, так как академические программы меняются ежегодно, но знание французского языка не ниже уровня B2, как правило, один из критериев для поступления в магистратуру Франции. 
                </p>
                <p>
                    Практически бесплатно учиться на магистратуре во Франции на английском языке будет возможно, если вы поступите в высшую школу (grande école) по программе alternance. Тогда за ваше образование будет платить предприятие. Учебу вы будете совмещать с практикой. Например, 2 дня в вузе и 3 на работе. Это хороший вариант не только с финансовой точки зрения, но и для дальнейшего поиска работы. Если вы проявите себя ответственным сотрудником, то компания может предложить вам постоянный контракт по окончанию учебы. Найти контракт alternance можно во время карьерных ярмарок, которые периодически устраиваются в Париже, либо самостоятельно через поисковые агрегаторы. Для участие в программе есть ограничение по возрасту, кандидат должен быть моложе 29 лет. Магистратура во Франции на английском бесплатно - вариант сложный, но возможный. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Финансирование магистратуры во Франции
            </template>
            <template #content>
                <p>
                    Вложение в образование – важный приоритет для французов. Многие французские банки предоставляют специальные кредиты студентам. Даже не имея внушительных средств на оплату учебы в престижном вузе, французские студенты получают шанс на образование мечты. Во Франции совершенно необязательно после бакалавриата поступать сразу на магистратуру, которая обычно длится 2 года. Очень много молодых людей предпочитают сначала поработать и накопить средства и только потом снова поступать в вуз и продолжать обучение. Российские студенты могут сэкономить на оплате образования в магистратуре, поступив только на второй год обучения. Например, если на родине вы имеете 5 лет учебы в университете, то во Франции это зачтется за 4 года. Это дает возможность поступить сразу на последний год магистратуры, а значит оплата обучения будет в 2 раза меньше изначальной. 
                </p>
                <p>
                    Что касается возраста для поступления в магистратуру во Франции, то здесь нет определенных ограничений. Большинство учащихся магистратуры в возрасте 23-26 лет, но есть и студенты, которым за 30, 40 или 50 лет. Во Франции считается абсолютно нормальным получать образование в любом возрасте. Для тех, кто хочет учиться с людьми более опытными, хорошим вариантом будет поступление на программу MBA или Executive MBA. При этом цены вырастут в несколько раз.
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Гранты и стипендии для бесплатной магистратуры во Франции. Возможно ли это ?
            </template>
            <template #content>
                <p>
                    Сразу стоит сказать, что абсолютно бесплатной ваша магистратура в любом случае вряд ли получится. При удачном раскладе вы сможете сэкономить существенные деньги, например, заплатив не полную стоимость за программу обучения, а лишь 50 %. Что же нужно для того, чтобы выиграть грант или стипендию? Здесь есть много факторов, большинство из которых зависит от спонсора вашего финансирования. Иногда гранты и стипендии предоставляют государство и общественные организации, какую-то часть может предложить вуз или коммерческие фирмы. Например, если для одних структур критерием отбора будет высокая академическая успеваемость и профессиональные достижения, хорошее мотивационное письмо, то для других – низкий доход родителей и невозможность оплаты обучения в 100 % размере.
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
                Через сколько окупится магистратура во Франции?
            </template>
            <template #content>
                <p>
                    Все будет зависеть от выбора программы обучения и высшего учебного заведения, а также от вашей целеустремленности и профессионального проекта. Во многих высших школах предусмотрены стажировки на предприятии как критерий успешного окончания программы и получения диплома. При этом студент сам должен найти стажировку. Выбранное учебное заведение будет всячески помогать, устраивая карьерные ярмарки и проверяя вашу готовность к разговору с потенциальным работодателям. Практически у каждой бизнес-школы есть свои карьерные центры. Большинство стажировок во Франции являются платными, на срок от 3-6 месяцев. Государство периодически пересматривает нормы оплаты, обычно повышая их.
                </p>
                <p>
                    Примерная минимальная ежемесячная зарплата на стажировке во Франции в 2021 году:
                </p>
                <ul>
                    <li>Январь – Февраль - Ноябрь (20 дней работы) : 546 EUR</li>
                    <li>Март (23 дня работы) : 627,90 EUR</li>
                    <li>Апрель- Июль – Октябрь (21 день работы) : 573,30 EUR</li>
                    <li>Май (19 дней работы) : 518,70 EUR</li>
                    <li>Июнь - Август - Сентябрь – Декабрь (22 дня работы) : 600,60 EUR</li>
                </ul>
                <p>
                    Это лишь минимальный тариф, который должен соблюдать каждый работодатель. Многое будет зависеть от специальности и выбранного сектора. Например, рекламные агенства во Франции обычно платят минимальную зарплату стажерам. Банки, финансовые институты, индустрия и консалтинговые фирмы наоборот предлагают хорошие условия, от 1300 евро в месяц. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Итог
            </template>
            <template #content>
                <p>
                    Бесплатная магистратура во Франции – все же скорее миф, чем реальность. Если еще недавно плата за поступление в вузы была символической, то сегодня и государственные вузы вынуждены повышать тарифы для иностранцев. Важно то, что учеба в престижной магистратуре во Франции окупается достаточно быстро. Здесь ценятся дипломы. Чем престижнее школа, тем больше шансов найти работу после окончания обучения. Чем лучше вуз, тем и выше будет зарплата выпускника. Во многих престижных школах средняя зарплата после окончания – 42.000 евро в год. 
                </p>
                <p>
                    Если вы сомневаетесь в выборе вуза или специальности во Франции, советуем вам обратиться к помощи профессионалов. Вы всегда можете связаться с нашим агентством по поводу консультации, и мы подберем вам программу в зависимости от вашей ситуацией и карьерных целей. 
                </p>
            </template>
          </PostBodySection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import PostHeader from '@/components/blog/slots/PostHeader.vue'
import PostBodyIntroduction from '@/components/blog/slots/PostBodyIntroduction.vue'
import PostBodySection from '@/components/blog/slots/PostBodySection.vue'
import SubNav from '@/components/common/slots/SubNav.vue'
export default {
  name: 'BlogPost2',
  components: {
    Navbar,
    PostHeader,
    PostBodyIntroduction,
    PostBodySection,
    SubNav
  }
}
</script>
